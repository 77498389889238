import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper,
	Content
} from './CircleHolder.style'

const displayName = 'CircleHolder'

const Component = props => {

	const {
		children,
		centerOnly
	} = props

	return (
		<Wrapper centerOnly={centerOnly}>
			<Content>
				{children}
			</Content>
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
