import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CenteredLayout from '../../layouts/CenteredLayout'
import Circle from '../../components/Circle'
import Paragraph from '../../components/Paragraph'
import styled from 'styled-components'
import { breakpoint } from '../../styles/variables'
import Heading from '../../components/Heading'

const displayName = 'Cost'

const StyledHeading = styled(Heading)`
	font-weight: 400;
`

const TextCircle = styled(Circle)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-height: 450px;
	max-width: 450px;
`

const Component = props => {
	const history = useHistory();

	const handleClick = () => {
		history.push('/team')
	}

	return (
		<CenteredLayout headerText="Cost">
			<TextCircle>
				<StyledHeading>$1.5M</StyledHeading>
			</TextCircle>
		</CenteredLayout>
	)
}

Component.displayName = displayName
export { Component }
