import React, { useContext, useEffect } from 'react'
import Header from '../../components/Header'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import GridLayout from '../../layouts/GridLayout'
import Timeline from './src/Timeline'

const displayName = 'Timeline'

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/phase3',
			next: '/team'
		})
	}, [setNavigationConfig])

	const header = (
		<Header
			heading="Rollout"
		/>
	)

	return (
		<GridLayout
			header={header}
			verticalCenterSection={<Timeline />}
		/>
	)
}

Component.displayName = displayName
export default Component
