import React from 'react'
import {
	Wrapper,
	Header,
	LeftSection,
	CenterSection,
	RightSection,
	Footer,
	VerticalCenterSection
} from './GridLayout.style'
import Container from '../../components/Container'

const displayName = 'GridLayout'

const Component = props => {
	
	const {
		header,
		leftSection,
		centerSection,
		rightSection,
		footer,
		verticalCenterSection,
		centerOnly
	} = props

	const centerSectionOnly = (
		<CenterSection>
			{centerSection}
		</CenterSection>
	)

	const centerSplitSection = (
		<>
			<LeftSection>
				{leftSection}
			</LeftSection>
			{centerSectionOnly}
			<RightSection>
				{rightSection}
			</RightSection>
		</>
	)

	const centerFullSection = (
		<VerticalCenterSection>
			{verticalCenterSection}
		</VerticalCenterSection>
	)

	const computedCenterSection = () => {
		if (verticalCenterSection) {
			return centerFullSection
		}

		if (centerOnly) {
			return centerSectionOnly
		}

		return centerSplitSection
	}

	const renderHeader = () => {
		if (centerOnly) {
			return
		}
		return (
			<Header>
				{header}
			</Header>
		)
	}

	const renderFooter = () => {
		if (centerOnly) {
			return
		}
		return (
			<Footer>
				{footer}
			</Footer>
		)
	}

	return (
		<Container>
			<Wrapper centerOnly={centerOnly}>
				{renderHeader()}
				{computedCenterSection()}
				{renderFooter()}
			</Wrapper>
		</Container>
	)
}

Component.displayName = displayName
export default Component
