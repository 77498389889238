import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper
} from './RightContentRenderer.style'
import FeaturedList from '../../../../components/FeaturedList'
import FeaturedListItem from '../../../../components/FeaturedListItem'
import List from '../../../../components/List'
import ListItem from '../../../../components/ListItem'

const displayName = 'RightContentRenderer'

const phase1 = (
	<FeaturedList
		title="Ends"
	>
		<ListItem>
			A/P/I Learnings
		</ListItem>
		<ListItem>
			Brand Strategy and Narrative
		</ListItem>
		<ListItem>
			eComm and Retail Strategy
		</ListItem>
		<ListItem>
			Social/Influencer and PR Strategy
		</ListItem>
		<ListItem>
			Sales and Distribution Strategy
		</ListItem>
		<ListItem>
			Teaming Strategy
		</ListItem>
	</FeaturedList>
)

const phase2 = (
	<FeaturedList
		title="Ends"
	>
		<ListItem>
			Consumer Journey
		</ListItem>
		<ListItem>
			Brand Book
		</ListItem>
		<ListItem>
			Campaign Concept, Copy, and Tactics
		</ListItem>
		<ListItem>
			Production and Activation Calendars
		</ListItem>
		<ListItem>
			Capabilities and Culture Guidelines
		</ListItem>
	</FeaturedList>
)

const content = [
	null,
	null,
	null,
	phase1,
	phase2,
	null,
	null,
	null
]

const Component = props => {

	const {
		step
	} = props

	const currentContent = content[step]


	return (
		<Wrapper>
			{currentContent}
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
