import React, { useEffect, useContext } from 'react'
import Circle from '../../components/Circle'
import CircleHolder from '../../components/CircleHolder'
import Paragraph from '../../components/Paragraph'
import GridLayout from '../../layouts/GridLayout'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'

const displayName = 'Culture'

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/culture',
			next: '/phase1'
		})
	}, [setNavigationConfig])

	const Center = (
		<CircleHolder centerOnly>
			<Circle>
				<Paragraph variant="featured">
					BRANDS, IN THIS WAY,<br />
					HAVE NEVER BEEN MORE<br />
					CREATIVELY LIBERATED…<br />
					<br />
					<b>
						…FOR THOSE VISIONARY ENOUGH<br />
						TO REALIZE THEIR POWER.
					</b>
				</Paragraph>
			</Circle>
		</CircleHolder>
	)

	return (
		<GridLayout
			centerOnly
			centerSection={Center}
		/>
	)
}

Component.displayName = displayName
export { Component }
