import styled from 'styled-components'
import { breakpoint, spacing } from '../../../../styles/variables'

const Wrapper = styled.div`
	margin-bottom: ${spacing.base}px;
`

const TimelineChart = styled.div`
	margin-bottom: ${spacing.base}px;
	height: calc(100vh - 400px);
	display: grid;
	grid-row-gap: 10px;
	height: calc(100vh - 400px);

	@media (min-width: ${breakpoint}px) {
		height: calc(100vh - 400px);
	}
`

const MonthLabel = styled.div `
	color: #2B2E34;
	text-transform: uppercase;
	letter-spacing: .2em;
`

const MonthLabelWrapper = styled.div `
	display: flex;
	justify-content: space-between;
`

export {
	Wrapper,
	TimelineChart,
	MonthLabel,
	MonthLabelWrapper
}
