import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ListItem from '../ListItem'

const displayName = 'FeaturedListItem'

const StyledListItem = styled(ListItem)`
	margin-bottom: 12px;
`

const Component = props => {

	const {
		children
	} = props
	
	return (
		<StyledListItem>
			{children}
		</StyledListItem>
	)
}

Component.displayName = displayName
export default Component
