import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import List from '../../components/List'
import ListItem from '../../components/ListItem'
import DiagramPositioner from '../../components/DiagramPositioner'
import diagram from './phase3-diagram.svg'
import GridLayout from '../../layouts/GridLayout'
import Header from '../../components/Header'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import CircleHolder from '../../components/CircleHolder'
import { ListHeading } from './Phase3.style'

const displayName = 'Phase2'

const PhaseNumber = styled.h2`
	font-size: 12px;
	text-align: center;
	font-weight: 300;
	text-transform: uppercase;
`

const Title = styled.h1`
	font-size: 30px;
	text-align: center;
	font-weight: 300;
`

const HeaderContent = (
	<Header>
		<PhaseNumber>Phase 03</PhaseNumber>      
		<Title>Activating</Title>     	
	</Header>
)



const leftDetails = (
	<>
		<ListHeading level={3}>TO BE SCOPED WITH GREATER CLARITY AROUND:</ListHeading>
		<List
			title="Means"
		>
			<ListItem>
				Overseeing retail buildout
			</ListItem>
			<ListItem>
				Producing cross-channel content and event Tactics
			</ListItem>
			<ListItem>
				PR features and placements
			</ListItem>
			<ListItem>
				Buying media
			</ListItem>
			<ListItem>
				Community management for social

			</ListItem>
			<ListItem>
				Disseminating 'Capabilities and Culture' guidelinesd
			</ListItem>
		</List>
	</>
)

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/phase2',
			next: '/rollout'
		})
	}, [setNavigationConfig])

	const center = (
		<CircleHolder>
			<DiagramPositioner
				yPosition={19.7}
				xAdjust={12.5}
				yAdjust={20}
			>
				<img src={diagram} alt="" />
			</DiagramPositioner>
		</CircleHolder>
	)

	const header = (
		<Header
			subHeading="Phase 03"
			heading="Activating"
		/>
	)

	return (
		<GridLayout
			header={header}
			centerSection={center}
			leftSection={leftDetails}
		/>
	)
}

Component.displayName = displayName
export default Component
