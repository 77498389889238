import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper,
	ChartItem,
	ChartText
} from './TimelineItem.style'

const displayName = 'TimelineItem'

const Component = props => {

	const {
		text,
		start,
		end
	} = props

	return (
		<Wrapper>
			<ChartText
				start={start}
			>
				{text}
			</ChartText>
			
			<ChartItem
				start={start}
				end={end}
			>
				
			</ChartItem>
		</Wrapper>
	)
}

// Component.propTypes = propTypes
// Component.defaultProps = defaultProps
Component.displayName = displayName
export default Component
