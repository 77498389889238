import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper,
	FullWidthContent,
	Left,
	Right,
	Center,
	Header
} from './GridLayoutNew.style'
import useViewUnit from 'use-view-unit'

const displayName = 'GridLayoutNew'

const Component = props => {
	const { vh, vmin, vmax } = useViewUnit()

	const {
		fullWidthContent,
		left,
		right,
		center,
		header,
		centerCenteredContent
	} = props

	return (
		<Wrapper
			centerCenteredContent={centerCenteredContent}
			vh={vh}
			vmin={vmin}
			vmax={vmax}
		>
			<Header>
				{header}
				{centerCenteredContent}
			</Header>
			<Left>
				{left}
			</Left>
			<Center>
				{center}
			</Center>
			<Right>
				{right}
			</Right>
			<FullWidthContent>
				{fullWidthContent}
			</FullWidthContent>
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
