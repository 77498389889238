import styled from 'styled-components'
import { breakpoint2, spacing } from '../../../../styles/variables'

const Wrapper = styled.div`
	@media (min-width: ${breakpoint2}px) {
		padding-top: ${spacing.sideContentPaddingTop}vh;
	}
`

export {
	Wrapper
}
