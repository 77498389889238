import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import {
	Wrapper,
	Next,
	Prev,
	Fullscreen,
	PrevArrow,
	NextArrow
} from './NextPreviousNavigation.style'
import arrow from './src/arrow.svg'

const displayName = 'NextPrevNavigation'

const Component = props => {
	const [ navigationConfig, setNavigationConfig ] = useContext(NavigationContext)
	
	const renderPrevElement = navigationConfig.prev && (
		<Prev to={navigationConfig.prev}>
			<PrevArrow
				src={arrow}
				ariaLabel="Previous"
			/>
		</Prev>
	)

	const renderNextElement = navigationConfig.next && (
		<Next to={navigationConfig.next}>
			<NextArrow
				src={arrow}
				ariaLabel="Next"
			/>
		</Next>
	)

	const renderFullscreenElement = navigationConfig.fullscreen && (
		<Fullscreen to={navigationConfig.fullscreen} />
	)
	
	return (
		<Wrapper>
			{renderPrevElement}
			{renderNextElement}
			{renderFullscreenElement}
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
