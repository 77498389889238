import styled from 'styled-components'
import List from '../List'
import Heading from '../../components/Heading'
import { breakpoint, spacing } from '../../styles/variables'

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 100px auto;
	grid-column-gap: ${spacing.baseSm}px;
	grid-template-areas: "title list";
	margin-bottom: ${spacing.base}px;

	@media (min-width: ${breakpoint}px) {
		grid-template-columns: min-content auto;
	}
`

const TitleWrapper = styled.div`
	grid-area: title;
	justify-self: flex-end;
`

const ListWrapper = styled.div `
	grid-area: list;
`

const StyledList = styled(List)`

`

const StyledHeading = styled(Heading)`
	font-weight: 400;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: .2em;
`

export {
	Wrapper,
	TitleWrapper,
	ListWrapper,
	StyledList,
	StyledHeading
}
