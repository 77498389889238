import styled, { css } from 'styled-components'
import { breakpoint, color } from '../../styles/variables'

const displayName = 'Paragraph'

const featuredStyles = css`
	text-align: center;
	text-transform: uppercase;
	color: ${color.black};
	font-size: 13px;
	line-height: 2;
	letter-spacing: 0.2em;
	line-height: 1.4;
`

const featuredBold = css`
	${featuredStyles}
	font-weight: bold;
`

const Component = styled.p`
	margin: 0;
	${({variant}) => variant === 'featured' && featuredStyles}
	${({variant}) => variant === 'featured-bold' && featuredBold}
`

Component.displayName = displayName
export default Component
