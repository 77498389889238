import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper
} from './HeaderRenderer.style'
import Header from '../../../../components/Header'

const displayName = 'HeaderRenderer'

const content = [
	null,
	null,
	null,
	{
		title: 'Visioning',
		subtitle: 'PHASE 01'
	},
	{
		title: 'Realizing',
		subtitle: 'PHASE 02'
	},
	{
		title: 'Activating',
		subtitle: 'PHASE 03'
	},
	{
		title: 'Rollout'
	},
	null
]

const Component = props => {

	const {
		step
	} = props

	const currentContent = content[step]
	
	return (
		<Header
			title={currentContent && currentContent.title}
			subtitle={currentContent && currentContent.subtitle}
		/>
	)
}

Component.displayName = displayName
export default Component
