import React from 'react'
import PropTypes from 'prop-types'
import {
	Img,
	Wrapper
} from './Circle.style'
import circle from './circle.svg'

const displayName = 'Circle'

const Component = props => (
	<Wrapper>
		<Img src={circle} />
		{props.children}
	</Wrapper>
)

Component.displayName = displayName
export default Component
