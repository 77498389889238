const color = {
	background: '#c1c1c1',
	offBlack: '#2B2E34',
	black: '#000000'
}

const type = {
	fontFamily: '\'Maison Neue\', sans-serif',
	fontSize: {
		base: 12
	},
	lineHeight: {
		base: 1.5
	}
}

const spacing = {
	base: 40,
	baseSm: 20,
	containerMaxWidth: 1600,
	sideContentPaddingTop: 20
}

const breakpoint = 768
const breakpoint2 = 1280

export {
	breakpoint,
	breakpoint2,
	color,
	type,
	spacing
}
