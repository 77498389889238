import styled, { css } from 'styled-components'
import { breakpoint, spacing } from '../../styles/variables'


const headerFooterSize = 124
const gridGapSize = spacing.base * 2
const desktopBreakpoint = 1240

const renderCenteredMobileStyles = render => {
	const styles = css`
		min-height: 100vh;
		grid-template-columns: 1fr;
		grid-template-areas:
			"CenterSection"
		;
	`
	return render && styles
}

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: auto;
	grid-row-gap: ${gridGapSize / 2}px;
	grid-template-areas:
		"Header"
		"CenterSection"
		"LeftSection"
		"RightSection"
		"Footer"
	;
	${({centerOnly}) => renderCenteredMobileStyles(centerOnly)}

	@media (min-width: ${breakpoint}px) {
		grid-column-gap: ${gridGapSize}px;
		grid-row-gap: ${gridGapSize}px;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"Header Header"
			"CenterSection CenterSection "
			"LeftSection RightSection"
			"Footer Footer"
		;
		${({centerOnly}) => renderCenteredMobileStyles(centerOnly)}
	}

	@media (min-width: ${desktopBreakpoint}px) {
		grid-column-gap: ${gridGapSize / 2}px;
		grid-row-gap: ${gridGapSize / 2}px;
		min-height: 100vh;
		grid-template-areas:
			"Header Header Header"
			"LeftSection CenterSection RightSection"
			"Footer Footer Footer"
		;
		grid-template-columns: 1fr minmax(0, 60vmin) 1fr;

		/* grid-template-rows: 124px minmax(0,500px) 124px; */
		grid-template-rows: minmax(124px, calc(15vh - 40px)) minmax(500px, 60vmin) 0;
	}
`

const Header = styled.div`
	grid-area: Header;
	align-self: flex-start;

	@media (min-width: ${desktopBreakpoint}px) {
		align-self: flex-end;
	}
`

const LeftSection = styled.div`
	grid-area: LeftSection;

	@media (min-width: ${desktopBreakpoint}px) {
		padding-top: 110px;
		padding-left: ${spacing.base}px;
	}
`

const CenterSection = styled.div`
	grid-area: CenterSection;
	display: flex;
	justify-content: center;
	align-items: center;
`

const RightSection = styled.div`
	grid-area: RightSection;

	@media (min-width: ${desktopBreakpoint}px) {
		padding-top: 110px;
		padding-right: ${spacing.base}px;
	}
`

const VerticalCenterSection = styled.div`
	grid-row-start: CenterSection;
	grid-row-end: RightSection;
	grid-column-start: LeftSection;
	grid-column-end: RightSection;

	@media (min-width: ${desktopBreakpoint}px) {
		grid-area: LeftSection / LeftSection / RightSection / RightSection;
	}
`

const Footer = styled.div`
	grid-area: Footer;
`

export {
	Wrapper,
	Header,
	LeftSection,
	CenterSection,
	RightSection,
	Footer,
	VerticalCenterSection
}
