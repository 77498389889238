import styled from 'styled-components'
import { position } from 'polished'
import Logo from '../../../../components/BrandLogo'


const Wrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`

const ContentWrapper = styled.div`
	position: absolute;
	${position(0)}
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledLogo = styled(Logo)`
	width: 115px;
`

const Image = styled.img`
	height: 100%;
	width: 100%;
`

export {
	Wrapper,
	Image,
	ContentWrapper,
	StyledLogo
}
