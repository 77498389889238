import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper,
	ListHeading
} from './LeftContentRenderer.style'
import FeaturedList from '../../../../components/FeaturedList'
import FeaturedListItem from '../../../../components/FeaturedListItem'
import List from '../../../../components/List'
import ListItem from '../../../../components/ListItem'
import { spacing } from '../../../../styles/variables'

const displayName = 'LeftContentRenderer'

const phase1 = (
	<FeaturedList
		title="Means"
	>
		<FeaturedListItem>
			<b>Absorbing</b> existing brand materials, trend forecasting, and brand benchmark site visits
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Facilitating</b> 'Alignment / Provocation / Innovation Workshop'
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Interviewing</b> stakeholder, subject matter expert, and lateral thinkers
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Researching</b> depth and breadth of creative influence and omni-channel 'storyselling'
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Analyzing</b> brand ecosystem and team dynamics, <b>synthesizing</b> insight, and <b>optimizing</b> commercial and creative opportunities
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Determining</b> brand strategy and its hero narrative, (followed by) eComm, retail, social/influencer, pr, sales, and distribution strategies
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Establishing</b> teaming strategy, inclusive of OGSM, org. structure, creative process, and brand stewardship
		</FeaturedListItem>
	</FeaturedList>
)

const phase2 = (
	<FeaturedList
		title="Means"
	>
		<FeaturedListItem>
			<b>Mapping</b> online and IRL consumer journey experience/behaviors for concepting, planning, and measurement
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Developing</b> a 'big idea' campaign for strategic launch, channel-specific brand identity assets (visual and verbal), retail experience design, and activation tactics
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Calendarizing</b> deliberate and ongoing production/rollout of content creation, social and influencer engagement, campaign launch and PR sustainment (editorial and events), and retail buildout
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Codifying</b> the organization's behavioral guidelines and guardrails, values system, and top-down/bottom-up accountability structures
		</FeaturedListItem>
	</FeaturedList>
)

const phase3 = (
	<>
		<div style={{ marginBottom: '12px' }}>
			<b>TO BE SCOPED WITH GREATER CLARITY AROUND:</b>
		</div>
		<List
			title="Means"
		>
			<ListItem>
				Overseeing retail buildout
			</ListItem>
			<ListItem>
				Producing cross-channel content and event Tactics
			</ListItem>
			<ListItem>
				PR features and placements
			</ListItem>
			<ListItem>
				Buying media
			</ListItem>
			<ListItem>
				Community management for social

			</ListItem>
			<ListItem>
				Disseminating 'Capabilities and Culture' guidelines
			</ListItem>
		</List>
		<div style={{marginBottom: `${spacing.base}px`}}></div>
	</>
)

const content = [
	null,
	null,
	null,
	phase1,
	phase2,
	phase3,
	null,
	null
]

const centerVerticallySteps = [ 5 ]

const Component = props => {

	const {
		step
	} = props

	const currentContent = content[step]

	return (
		<Wrapper
			centerVertically={centerVerticallySteps.includes(step)}
		>
			{currentContent}
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
