import React, { useState, useEffect } from 'react'
import { breakpoint } from '../../../../styles/variables'
import PropTypes from 'prop-types'
import {
	Wrapper,
	ContentWrapper,
	StyledLogo,
	Image
} from './DiagramRenderer.style'
import Paragraph from '../../../../components/Paragraph'
import Link from '../../../../components/Link'
import VisibilitySwitch from '../../../../components/VisibilitySwitch'
import circleMobile from './src/circle-mobile.svg'
import circle from './src/circle.svg'
import phase1 from './src/phase-1.svg'
import phase1Mobile from './src/phase-1-mobile.svg'
import phase2 from './src/phase-2.svg'
import phase3 from './src/phase-3.svg'

const displayName = 'DiagramRenderer'

const step1Content = (
	<Paragraph variant="featured">
		<b>
			INSPIRATION IS AN<br />
			AWAKENING, MANIFESTED<br />
			IN ALL HIGH ARTISTIC<br />
			ACHIEVEMENTS.<br />
		</b>
		-GIACOMO PUCCINI
	</Paragraph>
)

const step2Content = (
	<Paragraph variant="featured">
		<b>
			CULTURE TODAY IS OF<br/>
			CIRCULAR INSPIRATION,<br />
			SHAPED BY BOTH<br />
			DIVERGENCE AND SYNERGY:<br />
		</b>
		A PERFECT PARADOX<br />
		WHERE PAST IS FUTURE,<br />
		EXPRESSION IS COMMODITY,<br />
		AND TRUTH IS SUBJECTIVE.
	</Paragraph>
)

const step3Content = (
	<Paragraph variant="featured">
		BRANDS, IN THIS WAY,<br />
		HAVE NEVER BEEN MORE<br />
		CREATIVELY LIBERATED…<br />
		<br />
		<b>
			…FOR THOSE VISIONARY ENOUGH<br />
			TO REALIZE THEIR POWER.
		</b>
	</Paragraph>
)

const step7Content = (
	<Link
		to="https://andvest.co"
		external
		newTab
		style={{zIndex: '200'}}
	>
		<StyledLogo />
	</Link>
)

const content = [
	step1Content,
	step2Content,
	step3Content,
	null,
	null,
	null,
	null,
	step7Content
]

const Component = props => {
	const {
		step
	} = props

	const [ isMobile, setIsMobile ] = useState()

	useEffect(() => {
		const mq = window.matchMedia(`(min-width: ${breakpoint}px)`)

		const cb = mq => {
			setIsMobile(!mq.matches)
		}

		cb(mq)

		mq.addListener(cb)
	}, [])

	const renderCircle = () => {
		if (isMobile) {
			return circleMobile
		}
		return circle
	}

	const diagrams = [
		renderCircle(),
		renderCircle(),
		renderCircle(),
		phase1,
		phase2,
		phase3,
		null,
		renderCircle()
	]

	return (
		<Wrapper>
			<VisibilitySwitch visibleIndex={step}>
				{diagrams.map(diagram => diagram && (
					<Image src={diagram} />
				))}
			</VisibilitySwitch>
			<ContentWrapper>
				<VisibilitySwitch
					visibleIndex={step}
					mode="display"
				>
					{content.map(content => content)}
				</VisibilitySwitch>
			</ContentWrapper>
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
