import React from "react";
import { bool } from 'prop-types'
import { StyledContainer } from "./Container.styles";

const displayName = 'Container'

const propTypes = {
	fillHeight: bool,
	paddingTop: bool,
	paddingBottom: bool
}

const Component = props => {
	
	const {
		alignContent,
		children,
		className,
		fillHeight,
		paddingTop,
		paddingBottom
	} = props
	
	const ElementProps = {
		alignContent,
		className,
		fillHeight,
		paddingTop,
		paddingBottom,
	}
	
	return (
		<StyledContainer {...ElementProps}>
			{children}
		</StyledContainer>
	)
}

Component.propTypes = propTypes
Component.displayName = displayName
export default Component
