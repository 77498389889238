import React from 'react'
import { Wrapper, Inner, Footer } from './CenteredLayout.style'
import Header from '../../components/Header'

const displayName = 'CenteredLayout'

const Component = props => (
	<Wrapper {...props}>
		<Header
			number={props.headerSubText}
		>
			{props.headerText}
		</Header>
		<Inner>
			{props.children}
		</Inner>
		<Footer>
			{props.footer}
		</Footer>
	</Wrapper>
)

Component.displayName = displayName
export default Component
