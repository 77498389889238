import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { spacing } from '../../styles/variables'

const displayName = 'Header'

const Header = styled.header`
	padding-top: ${spacing.base}px;
`

const PhaseNumber = styled.h2`
	font-size: 14px;
	letter-spacing: .2em;
	text-align: center;
	font-weight: 300;
	text-transform: uppercase;
`

const Title = styled.h1`
	font-size: 40px;
	text-align: center;
	font-weight: 300;
`

const Component = props => {

	const {
		subtitle,
		title
	} = props

	return (
		<Header>
			<PhaseNumber>{subtitle}</PhaseNumber>
			<Title>{title}</Title>
		</Header>
	)
}

Component.displayName = displayName
export default Component
