import React, { useState } from 'react'
import { routes } from '../routes'
import OnNavigate from './src/OnNavigate'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'
import { NavigationContext } from './Navigation.context'
import NextPrevNavigation from '../../components/NextPrevNavigation'

const handleOnNavigate = () => {
	window.scrollTo(0, 0)
}

const renderRoutes = routes.map((route, key) => {
	const exact = route.exact || true
	return (
		<Route
			exact={exact}
			path={route.path}
			component={route.component}
			key={key}
		/>
	)
})

const Component = props => {
	const navigationConfig = useState({})
	
	return (
		<Router>
			<NavigationContext.Provider value={navigationConfig}>
				<OnNavigate onNavigate={handleOnNavigate} />
				<Switch>
					{renderRoutes}
					<Redirect to="/" />
				</Switch>
				{/* <NextPrevNavigation /> */}
			</NavigationContext.Provider>
		</Router>
	)
}

export default Component
