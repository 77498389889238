import styled from 'styled-components'
import {
	breakpoint,
	spacing
} from '../../styles/variables'
import Heading from '../../components/Heading'


const Wrapper = styled.div `
	min-height: 100vh;
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 85px auto auto;
	grid-row-gap: ${spacing.base}px;

	grid-template-areas:
		"header"
		"diagram"
		"details1"
	;

	@media (min-width: ${breakpoint}px) {
		grid-template-columns: 1fr 2fr 1fr;
		grid-template-rows: 100px 1fr 2fr;
		grid-row-gap: unset;
		grid-column-gap: ${spacing.base}px;
		grid-template-areas:
			"header header header"
			". diagram ."
			"details1 diagram details2"
		;
	}
`

const Header = styled.div `
	grid-area: header;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
`
const DiagramSection = styled.div `
	grid-area: diagram;
	display: flex;
	justify-content: center;
`
const Details1Section = styled.div `
	grid-area: details1;
`
const Details2Section = styled.div `
	grid-area: details2;

	@media (max-width: ${breakpoint - 1}px) {
		display: none;
	}
`

const MobileOnlyWrapper = styled.div `
	@media (min-width: ${breakpoint}px) {
		display: none;
	}
`

const ListHeading = styled(Heading)`
	font-weight: 400;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: .2em;
	margin-bottom: 12px;
`

export {
	Wrapper,
	Header,
	DiagramSection,
	Details1Section,
	Details2Section,
	MobileOnlyWrapper,
	ListHeading
}
