import styled, { css } from 'styled-components'
import { position } from 'polished'
import Link from '../Link'
import { breakpoint, spacing } from '../../styles/variables'

const arrowSpacing = spacing.base * 2

const Wrapper = styled.div`
	position: fixed;
	${position(0)}
	z-index: 100;
	display: grid;
	grid-template-areas: "prev next";
	grid-template-columns: 1fr 1fr;

	@media (min-width: ${breakpoint}px) {
		grid-template-areas: "prev . next";
		grid-template-columns: 1fr 4fr 1fr;
	}
`

const navigationItemStyles = css`
	display: flex;
	align-items: center;
	height: 100vh;
`

const Prev = styled(Link)`
	${navigationItemStyles}
	grid-area: prev;
	justify-content: flex-start;
	padding-left: ${arrowSpacing}px;
`

const arrowStyles = css`
	display: none;

	@media (min-width: ${breakpoint}px) {
		display: block;
	}
	height: 60px;
`

const PrevArrow = styled.img`
	${arrowStyles}
	transform: rotate(180deg);
`

const Next = styled(Link)`
	${navigationItemStyles}
	grid-area: next;
	justify-content: flex-end;
	padding-right: ${arrowSpacing}px;
`

const NextArrow = styled.img`
	${arrowStyles}
`

const Fullscreen = styled(Link)`
	grid-area: prev / prev / next / next;
`

export {
	Wrapper,
	Next,
	Prev,
	PrevArrow,
	NextArrow,
	Fullscreen
}
