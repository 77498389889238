import React, { useEffect, useContext } from 'react'
import Circle from '../../components/Circle'
import Paragraph from '../../components/Paragraph'
import GridLayout from '../../layouts/GridLayout'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import CircleHolder from '../../components/CircleHolder'

const displayName = 'Home'

const Component = props => {
	const [ navigationConfig, setNavigationConfig ] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: null,
			next: '/culture'
		})
	}, [setNavigationConfig])

	const Center = (
		// <CircleHolder>
			<Circle>
				<Paragraph variant="featured">
					<b>
						INSPIRATION IS AN<br/>
						AWAKENING, MANIFESTED<br />
						IN ALL HIGH ARTISTIC<br />
						ACHIEVEMENTS.<br />
					</b>
					-GIACOMO PUCCINI
				</Paragraph>
			</Circle>
		// </CircleHolder>
	)

	return (
		<GridLayout
			centerOnly
			centerSection={Center}
		/>
	)
}

Component.displayName = displayName
export { Component }
