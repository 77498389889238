import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import { color, type } from './variables'

const GlobalStyles = createGlobalStyle`
	${normalize()}
	
	min-width: 320px;
	padding-bottom: calc(env(safe-area-inset-bottom) * 2);
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	* {
		user-select: none;
	}

	body {
		background-color: ${color.background};
		font-family: ${type.fontFamily};
		font-size: ${type.fontSize.base}px;
		line-height: ${type.lineHeight.base};
	}

	h1, h2, h3, h4, h5, h6 {
		margin-top: 0;
		margin-bottom: 0;
	}

	a, button {
		-webkit-tap-highlight-color: transparent;
		transition: opacity 200ms ease-in-out;
		:hover {
			opacity: .75;
		}
	}
	
	*::-webkit-media-controls {
	  display: none;
	}

	*::-webkit-media-controls-overlay-play-button {
	  display: none;
	}

	*::-webkit-media-controls-panel {
	  display: none!important;
	  -webkit-appearance: none;
	}

	*::-webkit-media-controls-play-button {
	  display: none!important;
	  -webkit-appearance: none;
	}

	*::-webkit-media-controls-start-playback-button {
	  display: none!important;
	  -webkit-appearance: none;
	}
`

export {
	GlobalStyles
}
