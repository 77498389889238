import React, { useContext, useEffect } from 'react'
import CenteredLayout from '../../layouts/CenteredLayout'
import Circle from '../../components/Circle'
import styled from 'styled-components'
import Logo from '../../components/BrandLogo'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import Link from '../../components/Link'
import GridLayout from '../../layouts/GridLayout'
import CircleHolder from '../../components/CircleHolder'

const displayName = 'Cost'

const StyledLogo = styled(Logo)`
	height: 100px;
`

const TextCircle = styled(Circle)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-height: 450px;
	max-width: 450px;
`

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/rollout',
			next: null
		})
	}, [setNavigationConfig])

	const Center = (
		<CircleHolder>
			<Circle>
				<Link
					href="https://andvest.co"
					external
					newTab
				>
					<StyledLogo />
				</Link>
			</Circle>
		</CircleHolder>
	)
	
	return (
		<GridLayout
			centerOnly
			centerSection={Center}
		/>
	)
}

Component.displayName = displayName
export { Component }
