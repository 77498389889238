import styled, { css } from 'styled-components'
import Heading from '../../../../components/Heading'
import { breakpoint2, spacing } from '../../../../styles/variables'

const centerVerticallyStyles = css`
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-left: 80px;
`

const defaultAlignmentStyled = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: ${spacing.sideContentPaddingTop}vh;
`

const Wrapper = styled.div`
	@media (min-width: ${breakpoint2}px) {
		${({centerVertically}) => centerVertically ? centerVerticallyStyles : defaultAlignmentStyled}
	}
`

const ListHeading = styled(Heading)`
	font-weight: 400;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: .2em;
	margin-bottom: 12px;
`

export {
	Wrapper,
	ListHeading
}
