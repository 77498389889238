import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const variants = {
	NUMBERED: 'numbered',
	BULLET: 'bullet',
	UNSTYLED: 'unstyled'
}

const propTypes = {
	variant: PropTypes.oneOf(Object.values(variants))
}

const defaultProps = {
	variant: variants.UNSTYLED
}

const List = props => {
	
	const {
		children,
		variant,
		...propsBypass
	} = props
	
	const element = variant === variants.NUMBERED ? 'ol' : 'ul'
	
	
	const variantUnstyledStyle = css`
		list-style: none;
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 0;
	`
	
	const StyledComponent = styled[element]`
		${variant === variants.UNSTYLED && variantUnstyledStyle}
	`
	
	return (
		<StyledComponent {...propsBypass}>
			{children}
		</StyledComponent>
	)
}

List.propTypes = propTypes
List.defaultProps = defaultProps

export default List
