import React, { memo } from 'react'
import { number, string } from 'prop-types'
import styled from 'styled-components'

const displayName = 'VisibilitySwitch'

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-template-areas: "x";
	height: 100%;
	width: 100%;
`

const ChildWrapper = styled.div`
	grid-area: x;
	visibility: ${({show, mode}) => mode === "visibility" && !show && 'hidden'};
	display: ${({show, mode}) => mode === "display" && !show ? 'none' : 'flex'};
	align-items: center;
	justify-content: center;
`

const propTypes = {
	visibleIndex: number,
	tag: string
}

const defaultProps = {
	visibleIndex: 0,
	tag: 'div',
	mode: 'visibility'
}

const Component = memo(props => {
	const {
		children,
		visibleIndex,
		mode
	} = props
	
	const computedVisibleIndex = visibleIndex || 0
	
	const renderMultipleChildren = () => {
		return children.map((child, key) => (
			<ChildWrapper
				key={key}
				show={computedVisibleIndex === key}
				mode={mode}
			>
				{child}
			</ChildWrapper>
		))
	}
	
	const renderSingleChild = (
		<ChildWrapper
			show
		>
			{children}
		</ChildWrapper>
	)
	
	const renderChildren = () => {
		if(Array.isArray(children)) {
			return renderMultipleChildren()
		}
		return renderSingleChild
	}
	
	return (
		<Wrapper>
			{renderChildren()}
		</Wrapper>
	)
})

Component.defaultProps = defaultProps
Component.displayName = displayName
Component.propTypes = propTypes
export default Component
