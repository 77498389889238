import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { position } from 'polished'
import { backgroundVideo as data } from './data.js'

const displayName = 'BackgroundVideo'

const BackgroundVideo = styled.video`
	position: fixed;
	margin-top: -8px;
	margin-bottom: -8px;
	height: calc(100vmax + 160px);
	width: 100vw;
	z-index: -1;
	object-fit: cover;
	${position(0)}
`

const Component = props => {
	const videoRef = useRef()
	
	useEffect(() => {
		videoRef.current.play()
	}, [])
	
	return (
		<BackgroundVideo
			ref={videoRef}
			loop
			muted
			preload="auto"
			poster={data.poster && data.poster}
			autoPlay="autoplay"
			playsInline
		>
			<source
				src={data.src}
				type="video/mp4"
			/>
		</BackgroundVideo>
	)
}

Component.displayName = displayName
export default Component
