import React, { useContext, useEffect } from 'react'
import FeaturedList from '../../components/FeaturedList'
import FeaturedListItem from '../../components/FeaturedListItem'
import { MobileOnlyWrapper } from './Phase1.style'
import GridLayout from '../../layouts/GridLayout'
import DiagramPositioner from '../../components/DiagramPositioner'
import diagram from './phase1-diagram.svg'
import Header from '../../components/Header'
import CircleHolder from '../../components/CircleHolder'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'


const displayName = 'Phases'

const rightDetails = (
	<FeaturedList
		title="Ends"
	>
		<FeaturedListItem>
			A/P/I Learnings
			</FeaturedListItem>
		<FeaturedListItem>
			Brand Strategy and Narrative
			</FeaturedListItem>
		<FeaturedListItem>
			eComm and Retail Strategy
			</FeaturedListItem>
		<FeaturedListItem>
			Social/Influencer and PR Strategy
			</FeaturedListItem>
		<FeaturedListItem>
			Sales and Distribution Strategy
			</FeaturedListItem>
		<FeaturedListItem>
			Teaming Strategy
			</FeaturedListItem>
	</FeaturedList>
)

const leftDetails = (
	<FeaturedList
		title="Means"
	>
		<FeaturedListItem>
			<b>Absorbing</b> existing brand materials, trend forecasting, and brand benchmark site visits
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Facilitating</b> 'Alignment / Provocation / Innovation Workshop'
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Interviewing</b> stakeholder, subject matter expert, and lateral thinkers
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Researching</b> depth and breadth of creative influence and omni-channel 'storyselling'
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Analyzing</b> brand ecosystem and team dynamics, <b>synthesizing</b> insight, and <b>optimizing</b> commercial and creative opportunities
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Determining</b> brand strategy and its hero narrative, (followed by) eComm, retail, social/influencer, pr, sales, and distribution strategies
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Establishing</b> teaming strategy, inclusive of OGSM, org. structure, creative process, and brand stewardship
		</FeaturedListItem>
	</FeaturedList>
)

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/brands',
			next: '/phase2'
		})
	}, [setNavigationConfig])

	const center = (
		<CircleHolder>
			<DiagramPositioner
				yPosition={3.5}
				xAdjust={12.5}
				yAdjust={10}
			>
				<img src={diagram} alt="" />
			</DiagramPositioner>
		</CircleHolder>
	)

	const header = (
		<Header
			subHeading="Phase 01"
			heading="Visioning"
		/>
	)

	return (
		<>
			{/* {header} */}
			<GridLayout
				header={header}
				centerSection={center}
				leftSection={leftDetails}
				rightSection={rightDetails}
			/>
		</>
	)
}

Component.displayName = displayName
export default Component
