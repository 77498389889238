import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper,
	TimelineChart,
	MonthLabelWrapper,
	MonthLabel,
} from './Timeline.style'
import TimelineItem from '../TimelineItem'

const displayName = 'Timeline'

const Component = props => {
	return (
		<Wrapper>
			<TimelineChart>
				<TimelineItem
					text="Absorb"
					start={5}
					end={10}
				/>
				<TimelineItem
					text="Facilitate"
					start={10}
					end={15.5}
				/>
				<TimelineItem
					text="Interviews"
					start={15}
					end={35}
				/>
				<TimelineItem
					text={<>Analyzing /&nbsp;Synthesizing /&nbsp;Optimizing</>}
					start={20}
					end={40.5}
				/>
				<TimelineItem
					text="Determining"
					start={35}
					end={64.5}
				/>
				<TimelineItem
					text="Establishing"
					start={35}
					end={59.5}
				/>
				<TimelineItem
					text="Mapping"
					start={54.5}
					end={70}
				/>
				<TimelineItem
					text="Developing"
					start={54.5}
					end={84.5}
				/>
				<TimelineItem
					text="Calendarizing"
					start={74.5}
					end={100}
				/>
				<TimelineItem
					text="Codifying"
					start={50}
					end={64.5}
				/>

			</TimelineChart>



			<MonthLabelWrapper>
				<MonthLabel>
					Dec
				</MonthLabel>
				<MonthLabel>
					Jan
				</MonthLabel>
				<MonthLabel>
					Feb
				</MonthLabel>
				<MonthLabel>
					Mar
				</MonthLabel>
				<MonthLabel>
					Apr
				</MonthLabel>
				<MonthLabel>
					May
				</MonthLabel>
			</MonthLabelWrapper>
		</Wrapper>
	)
}

// Component.propTypes = propTypes
// Component.defaultProps = defaultProps
Component.displayName = displayName
export default Component
