import React, { useEffect } from 'react'
import { GlobalStyles } from './styles/global'
import AppRouter from './routes/AppRouter'
import BackgroundVideo from './components/BackgroundVideo'

import Phase1Diagram from './pages/Phase1/phase1-diagram.svg'
import Phase2Diagram from './pages/Phase2/phase2-diagram.svg'
import Phase3Diagram from './pages/Phase3/phase3-diagram.svg'

const App = () => {
	useEffect(() => {
		const imagesToBePreloaded = [Phase1Diagram, Phase2Diagram, Phase3Diagram]
	    imagesToBePreloaded.forEach(image => { new Image().src = image })
	}, [])
	
	return (
		<>
			<BackgroundVideo />
			<GlobalStyles />
			<AppRouter />
		</>
	)
}
export default App;
