import React from 'react'
import styled from 'styled-components'
import { color } from '../../styles/variables'

const displayName = 'BrandLogo'

const Svg = styled.svg`
	display: block;
	fill: ${color.black};
	overflow: visible;
	width: 100%;
`

const Component = props => {
	
	const svgProps = {
		xmlns: 'http://www.w3.org/2000/svg',
		viewBox: '0 0 155.31 82.03',
	}
	
	return (
		<Svg {...props} {...svgProps}>
			<title>AndVest</title>
			<g>
				<path d="M16,45.3a6.83,6.83,0,0,1-4.9-2.11A6.55,6.55,0,0,1,9.3,38.52a6.74,6.74,0,0,1,3-5.5L14,31.77l7.79,10.76A6.86,6.86,0,0,1,16,45.3m18-3.2A19.88,19.88,0,0,0,36.27,33h-10L14.71,16.14c-2.8-4.21.1-7.74,3.34-7.74a4.46,4.46,0,0,1,4.52,4.4,4.08,4.08,0,0,1-.1.93c0,.19-.08.38-.13.57A5.52,5.52,0,0,1,20.45,17l4.84,6.9C36.47,15.7,31.39,0,17.71,0,9,0-.6,9.64,7.16,22.24l1.31,2L6.82,25.58C2.23,29.23,0,33.48,0,38.54A15,15,0,0,0,4.89,49.86a16.43,16.43,0,0,0,11.88,4.69,16,16,0,0,0,9.19-3l1.64-1.11,2.27,3.33H40.45L33.4,43.21Z"
				/>
				<path d="M155.31,58.16H127.73v7h9v16.9h9.56V65.16h9v-7Zm-43,8c-3.45-.27-5.09-.51-5.09-1.3s2.32-1.13,4.4-1.13A17.56,17.56,0,0,1,120.93,66l4.34-5.29c-3.62-2.25-8.74-3.38-13.69-3.38-7.92,0-13.75,2.93-13.75,8.29,0,5.6,6.59,7.34,14.13,8,4,.34,4.92.72,4.92,1.54s-1.33,1.26-4.13,1.26a21,21,0,0,1-11.43-3.24l-4.57,5.26c4,2.83,9.56,4.44,15.87,4.44,9,0,13.65-3.41,13.65-8.74,0-5.83-5.81-7.33-14-8M76.67,75.43V72.94h17V67h-17V64.78H93.91V58.16H67.32V82.05h27V75.43ZM32,58.16,46,82.05H56L64,58.16H54L49.91,71.35,42.13,58.16Z"
				/>
			</g>
		</Svg>
	)
}

Component.displayName = displayName
export default Component
