import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import FeaturedList from '../../components/FeaturedList'
import FeaturedListItem from '../../components/FeaturedListItem'
import ListItem from '../../components/ListItem'

import {
	MobileOnlyWrapper
} from './Phase2.style'
import DiagramPositioner from '../../components/DiagramPositioner'
import Header from '../../components/Header'
import GridLayout from '../../layouts/GridLayout'
import diagram from './phase2-diagram.svg'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import CircleHolder from '../../components/CircleHolder'

const displayName = 'Phase2'

const PhaseNumber = styled.h2`
	font-size: 12px;
	text-align: center;
	font-weight: 300;
	text-transform: uppercase;
`

const Title = styled.h1`
	font-size: 30px;
	text-align: center;
	font-weight: 300;
`

const HeaderContent = (
	<Header>
		<PhaseNumber>Phase 02</PhaseNumber>      
		<Title>Realizing</Title>     	
	</Header>
)

const rightDetails = (
	<FeaturedList
		title="Ends"
	>
		<ListItem>
			Consumer Journey
		</ListItem>
		<ListItem>
			Brand Book
		</ListItem>
		<ListItem>
			Campaign Concept, Copy, and Tactics
		</ListItem>
		<ListItem>
			Production and Activation Calendars
		</ListItem>
		<ListItem>
			Capabilities and Culture Guidelines
		</ListItem>
	</FeaturedList>
)

const leftDetails = (
	<FeaturedList
		title="Means"
	>
		<FeaturedListItem>
			<b>Mapping</b> online and IRL consumer journey experience/behaviors for concepting, planning, and measurement
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Developing</b> a 'big idea' campaign for strategic launch, channel-specific brand identity assets (visual and verbal), retail experience design, and activation tactics
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Calendarizing</b> deliberate and ongoing production/rollout of content creation, social and influencer engagement, campaign launch and PR sustainment (editorial and events), and retail buildout
		</FeaturedListItem>
		<FeaturedListItem>
			<b>Codifying</b> the organization's behavioral guidelines and guardrails, values system, and top-down/bottom-up accountability structures
		</FeaturedListItem>
	</FeaturedList>
)

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/phase1',
			next: '/phase3'
		})
	}, [setNavigationConfig])

	const center = (
		<CircleHolder>
			<DiagramPositioner
				yPosition={3.5}
				xAdjust={12.5}
				yAdjust={10}
			>
				<img src={diagram} alt="" />
			</DiagramPositioner>
		</CircleHolder>
	)

	const header = (
		<Header
			subHeading="Phase 02"
			heading="Realizing"
		/>
	)

	return (
		<GridLayout
			header={header}
			centerSection={center}
			leftSection={leftDetails}
			rightSection={rightDetails}
		/>
	)
}

Component.displayName = displayName
export default Component
