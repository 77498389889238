import React from "react";
import { Heading } from './Heading.style'

const displayName = 'Heading'

const Component = props => {
	const {
		children,
		...bypassProps
	} = props
	
	return (
		<Heading {...bypassProps}>
			{children}
		</Heading>
	)
}

Component.displayName = displayName
export default Component
