import styled, { css } from 'styled-components'
import { breakpoint } from '../../styles/variables'

const renderCenterOnlyStyles = render => {
	const styles = css`
		grid-template-rows: 1fr minmax(0, 500px) 1fr;
	`
	return styles
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100vmin;
	grid-template-areas:
		"content"
	;
	@media (min-width: ${breakpoint}px) {
		grid-template-columns: 1fr minmax(0, 550px) 1fr;
		grid-template-rows: 1fr minmax(0, 550px) 1fr;
		grid-template-areas:
			". . ."
			". content ."
			". . ."
		;
	}
`

const Content = styled.div`
	grid-area: content;
`

export {
	Wrapper,
	Content
}
