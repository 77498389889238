import React from 'react'
import {
	Wrapper,
	TitleWrapper,
	ListWrapper,
	StyledList,
	StyledHeading
} from './FeaturedList.style'

const displayName = 'FeaturedList'

const Component = props => {

	const {
		title,
		children
	} = props
	
	return (
		<Wrapper>
			<TitleWrapper>
				<StyledHeading level={3}>
					{title}
				</StyledHeading>
			</TitleWrapper>
			<ListWrapper>
				<StyledList>
					{children}
				</StyledList>
			</ListWrapper>
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
