import styled, { css } from 'styled-components'
import { position } from 'polished'
import Link from '../../../../components/Link'
import { breakpoint, spacing } from '../../../../styles/variables'

const arrowSpacing = spacing.base * 1.5

const Wrapper = styled.div`
	position: fixed;
	${position(0)}
	display: grid;
	grid-template-areas: "prev next";
	grid-template-columns: 1fr 1fr;
	/* @media (min-width: ${breakpoint}px) {
		grid-template-areas: "prev . next";
		grid-template-columns: 1fr 4fr 1fr;
	} */
`

const navigationItemStyles = css`
	height: ${({vh}) => vh()}px;
	background: none;
	border: none;
	outline: none;
	z-index: 100;
	cursor: pointer;
	padding: 0;

	/* @media (min-width: ${breakpoint}px) {
		display: flex;
		align-items: center;
	} */
`

const Prev = styled.button`
	${navigationItemStyles}
	grid-area: prev;

	/* @media (min-width: ${breakpoint}px) {
		justify-content: flex-start;
		padding-left: ${arrowSpacing}px;
	} */
`

const arrowStyles = css`
	display: none;

	@media (min-width: ${breakpoint}px) {
		display: block;
	}
	height: 60px;
`

const PrevArrow = styled.img`
	${arrowStyles}
	transform: rotate(180deg);
`

const Next = styled.button`
	${navigationItemStyles}
	grid-area: next;

	@media (min-width: ${breakpoint}px) {
		justify-content: flex-end;
		padding-right: ${arrowSpacing}px;
	}
`

const NextArrow = styled.img`
	${arrowStyles}
`

const Fullscreen = styled(Link)`
	grid-area: prev / prev / next / next;
`

export {
	Wrapper,
	Next,
	Prev,
	PrevArrow,
	NextArrow,
	Fullscreen
}
