import React from 'react'
import PropTypes from 'prop-types'
import {
	Wrapper
} from './FullWidthContentRenderer.style'
import Timeline from './src/Timeline'

const displayName = 'FullWidthContentRenderer'

const rollout = (
	<Timeline />
)

const content = [
	null,
	null,
	null,
	null,
	null,
	null,
	rollout,
	null
]

const Component = props => {

	const {
		step
	} = props

	return content[step]
}

Component.displayName = displayName
export default Component
