import Home from '../pages/Home'
import Phase1 from '../pages/Phase1'
import Phase2 from '../pages/Phase2'
import Phase3 from '../pages/Phase3'
import Timeline from '../pages/Timeline'
import Cost from '../pages/Cost'
import Team from '../pages/Team'
import Culture from '../pages/Culture'
import Brands from '../pages/Brands'
import Main from '../pages/Main'

const routes = [
	{
		path: '/',
		component: Main
	}
	// {
	// 	path: '/',
	// 	component: Home
	// },
	// {
	// 	path: '/culture',
	// 	component: Culture
	// },
	// {
	// 	path: '/brands',
	// 	component: Brands
	// },
	// {
	// 	path: '/phase1',
	// 	component: Phase1
	// },
	// {
	// 	path: '/phase2',
	// 	component: Phase2
	// },
	// {
	// 	path: '/phase3',
	// 	component: Phase3
	// },
	// {
	// 	path: '/rollout',
	// 	component: Timeline
	// },
	// {
	// 	path: '/cost',
	// 	component: Cost
	// },
	// {
	// 	path: '/team',
	// 	component: Team
	// },
	// {
	// 	path: '/main',
	// 	component: Main
	// },
]

export {
	routes
}
