import React, { useEffect, useContext } from 'react'
import Circle from '../../components/Circle'
import Paragraph from '../../components/Paragraph'
import GridLayout from '../../layouts/GridLayout'
import { NavigationContext } from '../../routes/AppRouter/Navigation.context'
import CircleHolder from '../../components/CircleHolder'

const displayName = 'Culture'

const Component = props => {
	const [navigationConfig, setNavigationConfig] = useContext(NavigationContext)

	useEffect(() => {
		setNavigationConfig({
			prev: '/',
			next: '/brands'
		})
	}, [setNavigationConfig])

	const Center = (
		<CircleHolder>
			<Circle>
				<Paragraph variant="featured">
					<b>
						CULTURE TODAY IS OF<br/>
						CIRCULAR INSPIRATION,<br />
						SHAPED BY BOTH<br />
						DIVERGENCE AND SYNERGY:<br />
					</b>
					A PERFECT PARADOX<br />
					WHERE PAST IS FUTURE,<br />
					EXPRESSION IS COMMODITY,<br />
					AND TRUTH IS SUBJECTIVE.
				</Paragraph>
			</Circle>
		</CircleHolder>
	)

	return (
		<GridLayout
			centerOnly
			centerSection={Center}
		/>
	)
}

Component.displayName = displayName
export { Component }
