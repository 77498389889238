import styled from 'styled-components'
import { position } from 'polished'
import { breakpoint, color } from '../../styles/variables'
import circle from './circle.svg'


const Wrapper = styled.div`
	position: relative;
	height: 100%;
	${position(0)}
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* background-image: url(${circle});
	background-position: center;
	background-repeat: no-repeat; */
`

const Img = styled.img`
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: contain;
	pointer-events: none;
`

export {
	Img,
	Wrapper
}
