import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint } from '../../styles/variables'

const displayName = 'DiagramPositioner'

const xPosition = 0
const yPosition = 3.5
const xAdjust = 12.5
const yAdjust = 10

const defaultProps = {
	xPosition: 0,
	yPosition: 0,
	xAdjust: 0,
	yAdjust: 0
}

const Wrapper = styled.div`
	/* height: 100%; */
	/* width: 100%; */
	/* max-width: 600px; */

	@media (min-width: ${breakpoint}px) {
		width: calc(100% + ${({xAdjust}) => xAdjust * 2}%);
		height: calc(100% + ${({yAdjust}) => yAdjust * 2}%);
		margin: -${({yAdjust}) => yAdjust}% -${({xAdjust}) => xAdjust}%;
		padding-top: ${({yPosition}) => yPosition}%;
		padding-left: ${({xPosition}) => xPosition}%;
	}
`

const Component = props => {

	const {
		children,
		xPosition,
		yPosition,
		xAdjust,
		yAdjust
	} = props

	const wrapperProps = {
		xPosition,
		yPosition,
		xAdjust,
		yAdjust
	}

	return (
		<Wrapper {...wrapperProps}>
			{children}
		</Wrapper>
	)
}

Component.defaultProps = defaultProps
Component.displayName = displayName
export default Component
