import styled from 'styled-components'

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`

const ChartText = styled.div`
	display: inline-block;
	padding-left: 12px;
	margin-left: ${({start}) => start}%;
	color: rgba(225, 225, 225, 75);
	font-size: 11px;
	letter-spacing: .2em;
	text-transform: uppercase;
	word-wrap: break-word;
	hyphens: auto;
`

const ChartItem = styled.div`
	position: absolute;
	border: #707070 solid 1px;
	display: inline-block;
	margin-left: ${({start}) => start}%;
	width: ${({start, end}) => end - start}%;
	height: 100%;
`

export {
	Wrapper,
	ChartItem,
	ChartText
}
