import styled from 'styled-components'

const Wrapper = styled.div`
	min-height: 100vh;
`

const Inner = styled.div`
	height: calc(100vh - 260px);
	display: flex;
	align-items: center;
	justify-content: center;
`

const Footer = styled.div`
	height: 100px;
	padding-top: 30px;
	
`

export {
	Wrapper,
	Inner,
	Footer
}
