import styled from 'styled-components'
import RenderedLink from './src/RenderedLink'
import { css } from 'styled-components'

const defaultStyles = css`
	transition: opacity 200ms ease-in-out;
	
	&:hover {
		opacity: .7;
	}
`

const Link = styled(RenderedLink)`
	${defaultStyles}
`

export {
	Link
}
