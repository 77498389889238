import styled from 'styled-components'
import HeadingRaw from './src/HeadingRaw'

const Heading = styled(HeadingRaw)`
	margin: 0;
`

export {
	Heading
}
