import React, { useState } from 'react'
import {
	Wrapper
} from './Main.style'
import Container from '../../components/Container'
import GridLayoutNew from '../../layouts/GridLayoutNew'
import DiagramRenderer from './src/DiagramRenderer'
import LeftContentRenderer from './src/LeftContentRenderer'
import RightContentRenderer from './src/RightContentRenderer'
import FullWidthContentRenderer from './src/FullWidthContentRenderer'
import HeaderRenderer from './src/HeaderRenderer'
import ArrowNavigation from './src/ArrowNavigation'

const displayName = 'Main'
const lastStep = 7

const Component = props => {
	const [ currentStep, setCurrentStep ] = useState(0)

	const isFirstStep = currentStep <= 0
	const isLastStep = currentStep >= lastStep

	const handleNextClick = () => {
		if (isLastStep) {
			return
		}

		window.scrollTo(0, 0)
		setCurrentStep(currentStep + 1)
	}
	const handlePrevClick = () => {
		if (isFirstStep) {
			return
		}
		
		window.scrollTo(0, 0)
		setCurrentStep(currentStep - 1)
	}
	const center = (
		<DiagramRenderer
			step={currentStep}
		/>
	)

	const left = (
		<LeftContentRenderer
			step={currentStep}
		/>
	)

	const right = (
		<RightContentRenderer
			step={currentStep}
		/>
	)

	const fullWidthContent = (
		<FullWidthContentRenderer
			step={currentStep}
		/>
	)

	const header = (
		<HeaderRenderer
			step={currentStep}
		/>
	)

	const centeredSteps = [0, 1, 2, 7]
	const centerCenteredContent = centeredSteps.includes(currentStep)
	return (
		<Wrapper>
			<Container>
				<GridLayoutNew
					centerCenteredContent={centerCenteredContent}
					header={header}
					left={left}
					center={center}
					right={right}
					fullWidthContent={fullWidthContent}
				/>
			</Container>
			<ArrowNavigation
				onPrevClick={handlePrevClick}
				onNextClick={handleNextClick}
				isFirstStep={isFirstStep}
				isLastStep={isLastStep}
			/>
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
