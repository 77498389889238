import React from 'react'
import {
	Wrapper,
	Next,
	Prev,
	PrevArrow,
	NextArrow
} from './ArrowNavigation.style'
import arrow from './src/arrow.svg'
import useViewUnit from 'use-view-unit'

const displayName = 'ArrowNavigation'

const Component = props => {
	const { vh } = useViewUnit()

	const {
		onPrevClick,
		onNextClick,
		isFirstStep,
		isLastStep
	} = props
	
	const renderPrevElement = !isFirstStep && (
		<Prev
			onClick={onPrevClick}
			vh={vh}
		>
			{/* <PrevArrow
				src={arrow}
				ariaLabel="Previous"
			/> */}
		</Prev>
	)

	const renderNextElement = !isLastStep && (
		<Next
			onClick={onNextClick}
			vh={vh}
		>
			{/* <NextArrow
				src={arrow}
				ariaLabel="Next"
			/> */}
		</Next>
	)
	
	return (
		<Wrapper>
			{renderPrevElement}
			{renderNextElement}
		</Wrapper>
	)
}

Component.displayName = displayName
export default Component
