import styled from 'styled-components'
import { breakpoint, breakpoint2, spacing } from '../../styles/variables'

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: ${
		props => {
			const {
				centerCenteredContent,
				vh,
				vmax
			} = props

			const row1 = centerCenteredContent ? `${vh(15)}px` : `minmax(100px, ${vh(15)}px)`
			const row2 = centerCenteredContent ? `${vmax(70) - spacing.base}px` : 'auto'

			return `${row1} ${row2} auto auto`
		}
	};
	grid-auto-columns: 100%;
	grid-row-gap: ${spacing.base / 2}px;
	grid-template-areas:
		"header"
		"center"
		"left"
		"right"
	;

	@media (min-width: ${breakpoint}px) {
		grid-template-rows: ${({centerCenteredContent}) => centerCenteredContent ? `15vh minmax(500px, calc(70vh - ${spacing.base * 2}px))` : 'minmax(100px, 15vmin) auto'} auto;
		grid-template-columns: 1fr minmax(0, 300px) minmax(0, 300px) 1fr;
		grid-column-gap: ${spacing.base / 2}px;
		grid-row-gap: ${spacing.base / 2}px;
		grid-template-areas:
			". header header ."
			". center center ."
			". left right ."
		;
	}

	@media (min-width: ${breakpoint2}px) {
		grid-template-rows: minmax(100px, 15vmin) minmax(500px, 70vmin);
		grid-template-columns: 1fr minmax(500px, 70vmin) 1fr;
		grid-row-gap: 0;
		grid-template-areas:
			"header header header"
			"left center right"
		;
	}
`

const FullWidthContent = styled.div`
	grid-column-start: center;
	grid-row-start: center;
	grid-row-end: right;

	@media (min-width: ${breakpoint}px) {
		grid-column-start: left;
		grid-column-end: right;
		grid-row-start: center;
		grid-row-end: left;
	}

	@media (min-width: ${breakpoint2}px) {
		grid-column-start: left;
		grid-column-end: right;
		grid-row-start: left;
		grid-row-end: left;
	}
`

const Left = styled.div `
	grid-area: left;
`

const Right = styled.div `
	grid-area: right;

`

const Center = styled.div `
	grid-area: center;
`

const Header = styled.div `
	grid-area: header;
	align-self: flex-end;
`

export {
	Wrapper,
	FullWidthContent,
	Left,
	Right,
	Center,
	Header
}
